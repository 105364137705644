.error-404 {
	background-color: white;
	background: url("../../public/404.jpg");
	background-size: 100%;
	z-index: 0;
	background-size: 100% 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
} 

.error-404 > h1 {
	color: white;
}

.error-404 > h3 {
	font-style: italic;
	font-weight: 400;
	color: lightgray;
}
